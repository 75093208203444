import _ from "lodash";
import React, { useEffect, useState, useRef, useCallback } from "react";
import dayjs from "dayjs";
import { connect, useSelector } from "react-redux";
import { useTranslate } from "react-redux-multilingual";
import { LoadingIcon, scrollTop, ShowPager, unufiedNewLine } from "components/standart";
import GiphyImage from "components/standart/giphyImage";
import LoadingModal from "components/standart/loading-modal";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
// import ShareButton, { contentId } from "components/standart/share-button";
import WebAppShareButton, { contentId } from "components/standart/webapp-share-button";
import LocalesValues, { getLocaledValue } from "components/standart/LocalesValues";
import MemeImage from "components/standart/memeImage";
import Typewriter from "typewriter-effect";
import ShowToday from "components/standart/showToday";
import nl2br from "react-nl2br";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import Col3Container from "components/standart/col3Container";
import BottomNaviSpacing from "components/standart/bottomNaviSpacing";
import { customerApi, fileUrl } from "reducers/api";

import PremiumBlock from "../premium";
import AddFriend from "./addFriend";
import EditFriendsBlock from "./friendsData";
import ShowFriendsModal from "./friendsDataModal";
import { FriendsNamesInfo, FriendZodiac } from "./friendsDataElements";
import PeriodicContent from "../periodicContent";
import ExtraInfoBlock from "../extraInfoBlock";
import { PlanetAspectElement, PlanetAspectSumary } from "../planetAspect";

const { api } = customerApi.actions;
SwiperCore.use([Pagination]);

const FriendsBlock = (props) => {
  const translate = useTranslate();
  const { sessionStore } = props;
  const { settings } = props.env.www;

  const { locales } = useSelector((state) => state);
  const [lockSwiper, setLockSwiper] = useState(false);
  const [contents, setContents] = useState([]);
  const [contentLoading, setContentLoading] = useState(false);
  const [currentContent, setCurrentContent] = useState(null);

  const [planetAspectData, setPlanetAspectData] = useState(null);

  const [availablePremium, setAvailablePremium] = useState(false);
  const [fullText, setFullText] = useState("");
  const [swiper, setSwiper] = useState(null);
  const [isLoaded, setLoaded] = useState(false);
  const [lastLoaded, setLastLoaded] = useState(false);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentFriend, setCurrentFriend] = useState(null);

  // проверка, что доступен бесплатный премиум
  useEffect(() => {
    if (sessionStore.premium) {
      setAvailablePremium(true);
    } else {
      const accountEndDay = dayjs(sessionStore.account.createdAt)
        .add(1, "day")
        .endOf("day")
        .toDate();
      const endOfToday = dayjs(sessionStore.today).endOf("day").toDate();
      // премиум доступен, если дата регистрации аккаунта не старше одного дня от текущей даты
      setAvailablePremium(accountEndDay > endOfToday);
    }
  }, [sessionStore.premium, sessionStore.account, sessionStore.today]);

  useEffect(() => {
    scrollTop();
  }, []);

  const slideChangeHandler = (event) => {
    setCurrentIndex(event.realIndex);
    return true;
  };

  const swiperRef = useRef(null);
  useEffect(() => {
    swiperRef.current = swiper;
  }, [swiper]);

  useEffect(() => {
    if (contents.length) {
      setCurrentContent(contents[currentIndex]);
    } else {
      setSwiper(null);
      setCurrentContent(null);
    }
  }, [lastLoaded, currentIndex]);

  useEffect(() => {
    if (currentContent && !lockSwiper) {
      // const { usedPlanet } = currentContent.compatibility.aspectOverlap;
      // const { aspectValue } = currentContent.compatibility;
      // setPlanetAspectData({ aspectValue, planet: usedPlanet });
      /*
      setAspectPlanet(_.toLower(translate(`planets_${usedPlanet}`)));
      if (aspectValue) {
        const { icon, localesName } = aspectValue;
        const aspect = getLocaledValue(localesName, locales.data?._id);
        setAspectText(translate("aspect_text", { icon, aspect }));

        // setAspectText(
        //   translate("moon_aspect_text", {
        //     icon,
        //     aspect: translate(`aspects_pred_${usedAstroAspect}`),
        //     planet: translate(`planets_tvor_${usedPlanet}`),
        //   }),
        // );
      } else {
        // setAspectText(
        //   translate("moon_aspect_default", { planet: translate(`planets_${usedPlanet}`) }),
        // );
        setAspectText(translate("aspect_default"));
      }
      */

      // setCurrentText(
      //   getLocaledValue(currentContent.daily.gradientText.localesText, locales.data?._id),
      // );

      const text = getLocaledValue(currentContent.periodQuoteText.localesText, locales.data?._id);
      // const text = getLocaledValue(
      //   currentContent.compatibility.aspectsCompatibility.localesText,
      //   locales.data?._id,
      // );
      // const upText = getLocaledValue(
      //   currentContent.compatibility.aspectsCompatibility.localesTextUp,
      //   locales.data?._id,
      // );
      // const downText = getLocaledValue(
      //   currentContent.compatibility.aspectsCompatibility.localesTextDown,
      //   locales.data?._id,
      // );

      // setFullText(`${text}`.replace(/\.\ /g, ".\n\n"));
      // setFullText(`${_.first(_.split(text, "\n")).trim()}`);

      const [text1, ...anotherText] = _.split(text, "\n").map(_.trim).filter(_.size);
      // setLargeText(`${_.trim(unufiedNewLine(anotherText.join("\n")))}`);
      setFullText(`${_.trim(unufiedNewLine([text1].join("\n")))}`);

      // if (!currentContent.compatibility.aspectsCompatibility.memeImageUrl) {
      //   // отключаем пока эту логику
      //   // loadContentElement(currentContent.compatibility._id);
      // }
    }
  }, [currentContent, lockSwiper]);

  const moveToSlideHandler = useCallback(
    (index) => {
      // /*
      const swipeToIndex = () => () => {
        swiperRef.current && swiperRef.current.slideTo(index || 0);
        // если индекс был передан точно, то скроллим экран наверх
        if (index >= 0) {
          scrollTop();
        }
      };
      setTimeout(swipeToIndex(), 100);
      // */
    },
    [swiper, swiperRef],
  );

  const openFriendModalHandler = useCallback(
    (index, friendId) => {
      setCurrentFriend(contents[index]);
      /*
      const swipeToIndex = () => () => {
        swiperRef.current && swiperRef.current.slideTo(index || 0);
        // если индекс был передан точно, то скроллим экран наверх
        if (index >= 0) {
          scrollTop();
        }
      };
      setTimeout(swipeToIndex(), 100);
      */
    },
    [contents],
  );

  const moveToFriendHandler = useCallback(
    (index, friendId) => {
      const swipeToIndex = () => () => {
        swiperRef.current && swiperRef.current.slideTo(index || 0);
        // если индекс был передан точно, то скроллим экран наверх
        if (index >= 0) {
          scrollTop();
        }
      };
      setTimeout(swipeToIndex(), 100);
    },
    [contents],
  );

  const loadContent = useCallback(() => {
    props.getContent(
      {},
      {
        onSuccess(body) {
          setContents(body);
          setLoaded(true);
          setLastLoaded(new Date());
          setContentLoading(false);
          moveToSlideHandler();
        },
        onRequest() {
          setContentLoading(true);
          // setIsLoaded(true);
        },
        onFailure() {
          setContentLoading(false);
        },
      },
    );
  }, [swiper, swiperRef]);

  const updateSwiperSize = useCallback(() => {
    // swiper.updateSize();
    swiper.updateAutoHeight(200);
  }, [swiper]);

  const [openModal, setOpenModal] = useState(null);
  const toggleModal = () => setOpenModal(!openModal);

  useEffect(() => {
    loadContent();
  }, [props.friendsRefresh]);

  return isLoaded ? (
    <div className="text-center">
      <div className="large-header">{translate("friends_today")}</div>
      <div className="pt-4" />

      <div className="position-relative">
        {contents.length ? (
          <>
            <div
              className="px-1 py-0 rounded standart-header"
              style={{
                position: "absolute",
                zIndex: 10,
                top: 0,
                right: 0,
                backgroundColor: "rgba(0, 0, 0, .38)",
                color: "white",
                fontSize: "0.9rem",
              }}
            >
              {`${currentIndex + 1}/${contents.length + 0}`}
            </div>

            <Swiper
              spaceBetween={50}
              slidesPerView={1}
              autoHeight={true}
              observer={true}
              observeSlideChildren={true}
              pagination={false}
              onSwiper={setSwiper}
              onSlideChange={slideChangeHandler}
            >
              {contents.map((dailyContent, index) => {
                return (
                  <SwiperSlide key={dailyContent._id}>
                    <div className="text-center">
                      {/* 
                      {dailyContent.compatibility.aspectsCompatibility.memeImageUrl ? (
                        <MemeImage
                          url={dailyContent.compatibility.aspectsCompatibility.memeImageUrl}
                          loadHandler={updateSwiperSize}
                        />
                      ) : (
                        <div className="giphy-image pt-5">
                          <LoadingIcon className="mt-5" />
                        </div>
                      )}
                      */}
                      {dailyContent.periodQuoteText?.memeImageUrl ? (
                        <MemeImage
                          url={dailyContent.periodQuoteText.memeImageUrl}
                          loadHandler={updateSwiperSize}
                        />
                      ) : (
                        <div className="giphy-image pt-5">
                          <LoadingIcon className="mt-5" />
                        </div>
                      )}
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>

            <div className="pt-2 pb-3">
              <ShowPager total={contents.length + 0} current={currentIndex} swiper={swiper} />
            </div>

            {currentContent && (
              <div>
                <div className="position-relative">
                  <div className="mb-4">
                    <FriendsNamesInfo
                      customer={sessionStore.account}
                      friend={currentContent.anotherCustomer || currentContent}
                    />
                  </div>

                  <div className="content-html position-relative">
                    <div className="text-center">{nl2br(fullText)}</div>

                    {/* 
                    <div className="pt-4" />
                    <PlanetAspectSumary
                      periodId={currentContent.periodQuoteText.periodId}
                      astroSign={
                        currentContent.anotherCustomer
                          ? currentContent.anotherCustomer.astroSign
                          : currentContent.astroSign
                      }
                    />
                     */}

                    <div className="pt-4" />
                    {availablePremium ? (
                      <button
                        className="btn btn-solid"
                        onClick={() => openFriendModalHandler(currentIndex)}
                      >
                        <i className="fa fa-eye mr-2" />
                        {translate("read_more")}
                      </button>
                    ) : (
                      <PremiumBlock
                        buttonIcon={"fa-lock"}
                        buttonText={translate("read_more_premium")}
                      />
                    )}
                  </div>
                </div>

                <div className="pt-4">
                  <WebAppShareButton share={"compatibility"} contentId={currentContent._id} />
                </div>
              </div>
            )}

            <ShowFriendsModal {...{ currentFriend, setCurrentFriend }} />

            <div className="pt-4">
              <EditFriendsBlock
                friends={contents}
                refreshHandler={loadContent}
                lockHandler={setLockSwiper}
                // clickHandler={moveToFriendHandler}
                isLocked={contentLoading}
              />
            </div>
          </>
        ) : (
          <div>
            {settings.noFriendsImageId ? (
              <div className="mb-4">
                <MemeImage url={fileUrl(settings.noFriendsImageId)} />
              </div>
            ) : null}
            <LocalesValues values={settings.noFriendsText} isHtml={false} defaultValue={""} />
            <div className="mt-4" />
            <AddFriend successHandler={loadContent} currentSize={contents.length} />
          </div>
        )}
      </div>

      <div className="pt-5" />
      <PeriodicContent type={"compatibility"} />

      <div className="pt-5" />
      <PeriodicContent type={"love"} />

      <div className="pt-5" />
      <ExtraInfoBlock />
    </div>
  ) : (
    <div className="p-5 text-center">
      <LoadingIcon />
    </div>
  );
};

const mapStateToProps = (state) => ({
  env: state.frontend.env,
  sessionStore: state.customer.session,
  friendsRefresh: state.navi.friendsRefresh,
});

export default connect(mapStateToProps, {
  getContent: api.get("/account/webapp/friends"),
})(FriendsBlock);
