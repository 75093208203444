import React from "react";
import { useSelector } from "react-redux";
import { LogoImage } from "components/standart";
import { useTranslate } from "react-redux-multilingual";
import { Link } from "react-router-dom";

function LogoElement(props) {
  const translate = useTranslate();
  const { env } = useSelector((state) => state.frontend);
  const { settings = {} } = env.www || {};

  return (
    <div className="mx-auto my-auto d-flex justify-content-center">
      {settings.logoImageId ? (
        <div className="text-right my-auto mr-2 pt-0">
          <Link to={props.webApp ? "/app" : "/"}>
            <LogoImage />
          </Link>
        </div>
      ) : null}
      <div className="text-left my-auto">
        <Link
          to={props.webApp ? "/app" : "/"}
          className="d-block -float-left my-auto"
          style={{ textDecoration: "none" }}
        >
          {/* <img src={`/logo.png`} alt="" className="img-fluid" style={{ height: "40px" }} /> */}
          <div className="header-logo">
            <div style={{ fontSize: "1.3em", fontFamily: "Arial Black", fontWeight: "bold" }}>
              {"astromeme"}
            </div>
          </div>
        </Link>

        <div
          style={{ fontSize: "0.77em", fontFamily: "Arial", marginTop: "-4px" }}
          className="text-lowercase"
        >
          {translate("sitename_helper")}
        </div>
        <div className="clearfix" />
      </div>
    </div>
  );
}

export default LogoElement;
