import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withTranslate } from "react-redux-multilingual";
import WebApp from "@twa-dev/sdk";
import { history } from "store";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

//
import { customerApi } from "reducers/api";
import { LoadingIcon } from "components/standart";
import Col3Container from "components/standart/col3Container";
import LocalesValues from "components/standart/LocalesValues";
import { WebAppUserpic } from "components/standart/webapp-userpic";
import BottomNaviSpacing from "components/standart/bottomNaviSpacing";

import { UsersTogetherPics } from "../common";
import { ConfettiBlock } from "../../confetti";

const { api, session } = customerApi.actions;

const GiftCheck = (props) => {
  const { sessionStore } = props;
  const { translate, systemEnv, successHandler } = props;
  const { settings } = props.env.www;

  const [loading, setLoading] = useState(false);
  const [giftData, setGiftData] = useState(null);
  const [giftAccepted, setGiftAccepted] = useState(false);
  const [giftWrong, setGiftWrong] = useState(false);

  useEffect(() => {
    const [action, giftId] = _.split(WebApp.initDataUnsafe.start_param, "-");
    if (action === "gift" && giftId) {
      // сбросим значение, чтобы не было повторений в случае штатной перезагрузки компонент
      WebApp.initDataUnsafe.start_param = null;
      props.checkGift(
        { body: { giftId } },
        {
          onSuccess(body) {
            setOpenModal(true);
            setGiftData(body);
          },
          onFailure() {
            // в случае ошибки отключим модалку вовсе
            // setOpenModal(true);
            // setGiftWrong(true);
          },
        },
      );
    }
  }, []);

  const completeClickHandler = useCallback(() => {
    if (giftWrong || giftAccepted) {
      props.getUser(
        {},
        {
          onRequest() {
            setLoading(true);
          },
          onSuccess() {
            giftAccepted && history.push("/app/friends");
            setOpenModal(false);
          },
        },
      );
    } else {
      acceptGiftHandler();
    }
  }, [giftWrong, giftAccepted, giftData]);

  const acceptGiftHandler = useCallback(() => {
    const giftId = giftData.gift._id;
    props.acceptGift(
      { body: { giftId } },
      {
        onSuccess() {
          setGiftAccepted(true);
          setLoading(false);
        },
        onRequest() {
          setLoading(true);
        },
        onFailure() {
          setLoading(false);
        },
      },
    );
  }, [giftData]);

  const [openModal, setOpenModal] = useState(null);
  const toggleModal = () => setOpenModal(!openModal);

  return (
    <>
      {openModal && (
        <Modal
          centered
          wrapClassName="fullscreen-modal"
          isOpen={openModal}
          size="md"
          // toggle={toggleModal}
        >
          {/* <ModalHeader toggle={toggleModal}></ModalHeader> */}
          <ModalBody className="py-0">
            <Col3Container>
              <ConfettiBlock active={giftAccepted} />

              {
                <div className="text-center">
                  <div className="large-header">
                    <LocalesValues
                      values={
                        giftWrong
                          ? settings.giftAction.actionFailureTitle
                          : giftAccepted
                          ? settings.giftAction.actionSuccessTitle
                          : settings.giftAction.confirmTitleText
                      }
                      isHtml={false}
                    />
                  </div>

                  <div className="standart-header my-4">
                    <LocalesValues
                      values={
                        giftWrong
                          ? settings.giftAction.actionFailureText
                          : giftAccepted
                          ? settings.giftAction.actionSuccessText
                          : settings.giftAction.confirmMainText
                      }
                      isHtml={false}
                    />
                  </div>

                  {giftData?.sender && !giftWrong ? (
                    <div className="py-4">
                      <UsersTogetherPics
                        account1={sessionStore.account}
                        account2={giftData.sender}
                      />
                    </div>
                  ) : null}

                  <div className="form-group text-center my-4">
                    <button
                      type="button"
                      className="btn btn-solid"
                      disabled={loading}
                      onClick={completeClickHandler}
                    >
                      {loading ? (
                        <LoadingIcon className="mr-2" />
                      ) : (
                        <i className="fa fa-check mr-2" />
                      )}
                      <LocalesValues
                        values={
                          giftWrong
                            ? settings.giftAction.actionFailureButton
                            : giftAccepted
                            ? settings.giftAction.actionSuccessButton
                            : settings.giftAction.confirmButtonText
                        }
                        isHtml={false}
                      />
                    </button>
                  </div>

                  {giftAccepted || giftWrong ? null : (
                    <div className="form-group text-center">
                      <button
                        type="button"
                        className="btn btn-outline"
                        disabled={loading}
                        onClick={toggleModal}
                      >
                        <i className="fa fa-times mr-2" />
                        {translate("decline")}
                      </button>
                    </div>
                  )}
                </div>
              }
            </Col3Container>
            <BottomNaviSpacing />
          </ModalBody>
        </Modal>
      )}
    </>
  );
};
const mapeStateToProps = (state) => ({
  systemEnv: state.customer.env,
  env: state.frontend.env,
  sessionStore: state.customer.session,
});
export default connect(mapeStateToProps, {
  ...session.actions,
  checkGift: api.post("/account/webapp/gifts/check"),
  acceptGift: api.post("/account/webapp/gifts/accept"),
})(withTranslate(GiftCheck));
