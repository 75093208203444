import _ from "lodash";
import React, { useState, useEffect, useRef } from "react";
import Sortable from "sortablejs";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import { connect, useSelector } from "react-redux";
import { useTranslate } from "react-redux-multilingual";
import { customerApi } from "reducers/api";
import Col3Container from "components/standart/col3Container";
import BottomNaviSpacing from "components/standart/bottomNaviSpacing";
import LocalesValues, { getLocaledValue } from "components/standart/LocalesValues";

import { FriendZodiac } from "./friendsDataElements";

import AddFriend from "./addFriend";

const { api, session } = customerApi.actions;

export const openFriend = "openFriend";

const EditFriendsBlock = (props) => {
  const { friends = [], refreshHandler, lockHandler, isLocked } = props;
  const translate = useTranslate();
  // const location = useLocation();
  const { settings } = useSelector((state) => state.frontend.env.www);

  const friendsListId = "friends-list-id";
  // const { account } = sessionStore;

  const [friendDataUpdate, setFriendDataUpdate] = useState(null);
  const [friendDelete, setFriendDelete] = useState(null);
  const [friendError, setFriendError] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const toggleModal = () => setOpenModal(!openModal);

  useEffect(() => {
    lockHandler(openModal);
    setIsOpened(openModal);
  }, [openModal]);

  const deleteInfo = (friendId) => () => {
    const confirm = window.confirm(translate("friend_delete_confirm"));
    if (confirm) {
      props.deleteFriend(
        { body: { friendId } },
        {
          onSuccess() {
            refreshHandler(); // обновим список после удаления записи
            // setOpenModal(false);
            // setFriendData(null);
            setFriendDataUpdate(false);
            // props.getUser();
          },
          onRequest() {
            setFriendDataUpdate(true);
            setFriendDelete(friendId);
            setFriendError(false);
          },
          onFailure({ message }) {
            setFriendDataUpdate(false);
            setFriendError(message);
          },
        },
      );
    }
  };

  const onCompleteDrag = (evt) => {
    console.log("drag complete", evt.oldIndex, evt.newIndex);
    const items = document.getElementById(friendsListId);
    const friendsId = [];
    items.childNodes.forEach((item) => friendsId.push(item.id));

    props.orderFriends(
      { body: { friendsId } },
      {
        onSuccess() {
          refreshHandler();
        },
        onRequest() {
          sortRef.current.destroy();
          sortRef.current = null;
        },
      },
    );
  };

  const sortRef = useRef(null);

  useEffect(() => {
    if (friends.length && openModal && isOpened) {
      if (sortRef.current) {
        sortRef.current.destroy();
      }

      // setTimeout(() => {
      const items = document.getElementById(friendsListId);
      sortRef.current = new Sortable(items, {
        handle: ".drag-handle", // handle's class
        animation: 150,
        forceFallback: true,
        ghostClass: "invisible",
        onEnd: onCompleteDrag,
      });
    }
  }, [friends, openModal, isOpened]);

  return (
    <div>
      <button className="btn btn-solid" onClick={toggleModal}>
        <i className="fa fa-user-plus mr-2" />
        {translate("friends_my")}
      </button>

      {openModal ? (
        <Modal
          centered
          // fullscreen={true}
          wrapClassName="fullscreen-modal"
          isOpen={openModal}
          size="lg"
          onOpened={() => setIsOpened(true)}
          onClosed={() => setIsOpened(false)}
          // toggle={toggleModal}
        >
          <ModalHeader toggle={toggleModal}></ModalHeader>
          <ModalBody>
            <Col3Container>
              <div className="large-header text-center">{translate("friends_list")}</div>
              <div className="mt-4" />
              <div className="text-center">
                <LocalesValues values={settings.noFriendsText} isHtml={false} defaultValue={""} />
              </div>
              <div className="mt-4" />

              <div
                id={friendsListId}
                className={`pr-4 text-center ${isLocked ? "text-disabled" : ""}`}
              >
                {_.map(friends, (friend, index) => {
                  return friendDelete !== friend._id ? (
                    <div
                      id={friend._id}
                      key={friend._id}
                      className={`row mb-3`}
                      // onClick={openInfo(friend._id)}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="col-1 px-2 text-left my-auto drag-handle">
                        <button className="btn btn-sm text-default">
                          <i className="fa fa-sort-alt" />
                        </button>
                      </div>

                      <div
                        className="col px-1 my-auto text-truncate"
                        onClick={
                          props.clickHandler ? () => props.clickHandler(index, friend._id) : null
                        }
                      >
                        <div className="d-flex">
                          <FriendZodiac
                            {...(friend.anotherCustomer || friend)}
                            withPhoto={true}
                            noModal={!!props.clickHandler}
                          />
                        </div>
                      </div>

                      {/* 
              <div className="col-1 text-center my-auto">
                {friend.compatibility.aspectValue.icon}
              </div>
              */}

                      <div className="col-1 text-left my-auto">
                        <button
                          className="btn btn-sm text-default"
                          onClick={deleteInfo(friend._id)}
                          disabled={friendDataUpdate}
                        >
                          <i className="far fa-trash-alt" />
                        </button>
                      </div>
                    </div>
                  ) : null;
                })}
              </div>

              <div className="text-center mt-4">
                <AddFriend
                  successHandler={() => {
                    toggleModal();
                    refreshHandler();
                  }}
                  currentSize={friends.length}
                />
              </div>
            </Col3Container>
            <BottomNaviSpacing />
          </ModalBody>
        </Modal>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  sessionStore: state.customer.session,
});

export default connect(mapStateToProps, {
  ...session.actions,
  orderFriends: api.post("/account/webapp/friends/order"),
  deleteFriend: api.post("/account/friends/delete"),
})(EditFriendsBlock);
