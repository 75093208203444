import _ from "lodash";
import React, { useEffect, useState, useRef } from "react";
import { connect, useSelector } from "react-redux";
import { history } from "store";
import { useTranslate, withTranslate } from "react-redux-multilingual";
import { Helmet } from "react-helmet";
import { useRouteMatch, Switch, Route } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { LoadingIcon, scrollTop } from "components/standart";
// import AssistantBlock from "components/standart/assistantBlock";
// import { init, useLaunchParams } from "@telegram-apps/sdk-react";
import { customerApi } from "reducers/api";
import WebApp from "@twa-dev/sdk";

import { setNavi } from "actions";
import Col3Container from "components/standart/col3Container";

// import DataBlock from "./data";
import BottomMenu from "./bottom_menu";
import WebAppContent from "./content";

const { auth, session } = customerApi.actions;

const WebAppPage = (props) => {
  const { url, path } = useRouteMatch();
  const { translate, sessionStore } = props;
  const { meta = {} } = props.env.www;
  const [isAuthError, setAuthError] = useState(false);
  const [isAuthProcessing, setAuthProcessing] = useState(true);

  useEffect(() => {
    props.telegramAuth(
      {
        body: { initData: WebApp.initData },
      },
      {
        onSuccess(body) {
          setAuthProcessing(false);
        },
        onRequest() {
          setAuthProcessing(true);
          setAuthError(false);
        },
        onFailure() {
          setAuthProcessing(false);
          setAuthError(true);
        },
      },
    );
  }, []);

  useEffect(() => {
    document.body.classList.add("theme-dark");
    scrollTop();
    props.setNavi(url);

    const [action] = _.split(WebApp.initDataUnsafe.start_param, "-");
    if (action === "friends") {
      // сбросим значение, чтобы не было повторений в случае штатной перезагрузки компонент
      WebApp.initDataUnsafe.start_param = null;
      history.push("/app/friends");
    }
  }, []);

  const {
    title = translate("title_index"),
    description = translate("title_description"),
    keywords = "",
  } = meta.account || {};

  return isAuthProcessing ? (
    <Modal size="md" centered={true} wrapClassName="fullscreen-modal" isOpen={true}>
      <ModalHeader></ModalHeader>
      <ModalBody>
        <div className="d-flex align-items-stretch py-5">
          <div className="flex-fill text-center align-self-stretch h-100 py-5">
            <div className="pt-5">
              <LoadingIcon />
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  ) : (
    <>
      <section className="p-0 m-0">
        <div className="container">
          <Col3Container>
            {isAuthProcessing ? (
              <div className="text-center p-5">
                <LoadingIcon />
              </div>
            ) : isAuthError ? (
              <AuthRequired />
            ) : sessionStore.account ? (
              <div>{sessionStore.account.astroSign ? <WebAppContent /> : null}</div>
            ) : null}
          </Col3Container>
        </div>
        <BottomMenu />
      </section>
    </>
  );
};

const AuthRequired = (props) => {
  const translate = useTranslate();
  const { settings } = useSelector((state) => state.frontend.env.www);

  return (
    <div className="text-center">
      <div className="large-header">{translate("telegram_app_only")}</div>
      <div className="mt-4">
        <a
          className="btn btn-solid telegram-btn"
          data-id="open-webapp-index"
          href={settings.telegramAppUrl}
        >
          <i className="fab fa-telegram-plane mr-2" />
          {translate("open_telegram")}
        </a>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  sessionStore: state.customer.session,
  systemEnv: state.customer.env,
  env: state.frontend.env,
  // assistant: state.customer.assistant,
});

export default withTranslate(
  connect(mapStateToProps, {
    ...auth.actions,
    setNavi,
  })(WebAppPage),
);
